<template>
  <div v-loading = "loading">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="申请提现" name="1"></el-tab-pane>
        <el-tab-pane label="提现记录" name="2"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName==1">
        <el-form :model="msgruForm" label-width="100px">
          <el-form-item label="提现奖励：">
            <el-input :disabled="true" v-model="msgruForm.WaitDrawBalance" style="width:150px"></el-input>
          </el-form-item>
          <el-form-item label="到账银行卡：">
            <div class = "flexRow2">
              <div class = "bluebox flexCol" v-if="msgruForm.CardNo">
                <div class = "ellipsis">{{msgruForm.BankName}}</div>
                <div>{{cutout(msgruForm.CardNo)}}</div>
                <div>{{msgruForm.CardHolderName}}</div>
              </div>
              <el-button type="text" style="margin-left:10px" @click="changebank">{{(!msgruForm.CardNo?'添加':'修改')+'银行卡'}}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="短信验证码：">
            <el-input v-model="Code" style="width:250px"></el-input>
            <el-button :disabled="codedisable" type="text" style="margin-left:10px" @click="getCode">{{Codetext}}</el-button>
            <div class = "smallfont">验证码将以手机短信的形式，发送至商城的超级管理员手机：{{cutout(msgruForm.Phone)}}，请注意查收</div>
          </el-form-item>
        </el-form>
        <el-button type="primary" style="width:200px;height:36px" @click="applyfor">申请提现</el-button>
      </div>
      <div v-else-if="activeName==2">
        <el-table :data="tablelist" v-loading = "tableloading" class = "table" :header-row-style="{'height':'48px'}">
          <el-table-column prop="" label="申请人">
            <template slot-scope="scope">
              <div>{{scope.row.Name}}{{scope.row.Phone?'('+scope.row.Phone+')':''}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="申请时间"></el-table-column>
          <el-table-column prop="" label="提现金额" width="100px">
            <template slot-scope="scope">
              ￥{{scope.row.WithdrawalMoney}}
            </template>
          </el-table-column>
          <el-table-column prop="WithdrawalStateValue" label="提现结果"></el-table-column>
          <el-table-column prop="CheckTime" label="审核时间">
            <template slot-scope="scope">
              {{scope.row.CheckTime?scope.row.CheckTime:'— —'}}
            </template>
          </el-table-column>
          <el-table-column prop="" label="到账银行卡" width="300px">
            <template slot-scope="scope">
              {{scope.row.BankName+'('+cutout(scope.row.CardNo)+')'}}
            </template>
          </el-table-column>
          <el-table-column prop="" label="备注" width="300px">
            <template slot-scope="scope">
              {{scope.row.FailReason?scope.row.FailReason:'— —'}}
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align:right;margin-top:10px" v-if="total">
           <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="bankshow" title="添加银行卡" @closed = "closebank" v-loading = "bankshowloading">
      <el-form :model="bankruForm" label-width="130px" style="width:600px" v-loading="bankloading">
        <el-form-item class = "redstar" label="开户银行：">
          <el-select v-model="bankruForm.BankCode" @change="bankChange" filterable placeholder="请选择开户银行" style="width:100%">
            <el-option v-for="item in bankList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label = "开户行支行：">
          <el-input v-model="bankruForm.BankBranch" placeholder="请输入开户行支行，支行信息请包含省市区信息" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item class = "redstar" label="银行卡号：">
          <el-input v-model="bankruForm.CardNo" placeholder="请输入银行卡号"></el-input>
        </el-form-item>
        <el-form-item class = "redstar" label="开户人姓名：">
          <el-input v-model="bankruForm.CardHolderName" placeholder="请输入银行卡开户人姓名" maxlength="50"></el-input>
        </el-form-item>
        <div style="text-align:right;margin-top:30px">
          <el-button @click="closebank">取消</el-button>
          <el-button type="primary" @click="savebank">保存</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync = "imgcodeshow" width="550px" top="40vh" title="图片验证码" @close = "closeimgcode">
      <div class = "flexRow">
        <span>图片验证码：</span>
        <el-input v-model="imgcode" style="width:200px"></el-input>
        <div class = "flexCol2">
          <div id="imgcode" style="width:200px" ></div>
          <el-button type="text" @click="getimgCode">看不清？换一张</el-button>
        </div>
      </div>
      <div style="text-align:center;margin-top:20px">
        <el-button @click="closeimgcode">取消</el-button>
        <el-button type="primary" @click="comfirmimgcode">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import {
  recommrndawrdmallwithdrawalaccount,
  recommrndawrdwithdrawalaccountsave,
  recommrndawrdsmswithdrawalsmsimgcode,
  recommrndawrdsmswithdrawalsendsmscode,
  recommrndawrdmallapplywithdrawal,
  recommrndawrdmallwithdrawalrecord
} from "@/api/sv1.0.0"
export default {
  data () {
    return {
      loading:false,
      activeName:'1',
      msgruForm:{
        WaitDrawBalance:0,
        BankName:'',
        BankCode:'',
        BankBranch:'',
        CardNo:'',
        CardHolderName:'',
        Phone:''
      },
      Codetext:'获取验证码',
      Code:'',
      imgcodeshow:false,
      imgcode:'',
      time:null,
      codedisable:false,
      bankshow:false,
      bankList:[],
      bankloading:false,
      bankruForm:{},
      bankshowloading:false,


      tablelist:[],
      tableloading:false,
      currentPage:1,
      sizepage:20,
      total:null
    }
  },
  created () {
    this.getmessage()
  },
  methods: {
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    //图片验证码确认
    comfirmimgcode(){
      if(!this.imgcode){
        this.$message.error('请输入图片验证码')
      }else{
        this.sendCode()
        // this.timing()
        // this.imgcodeshow = false
      }
    },
    closeimgcode(){
      this.imgcodeshow = false
      this.imgcode = ''
    },
    getCode(){
      if(!this.time){
        this.imgcodeshow = true
        this.getimgCode()
      }
      // this.timing()
    },
    //获取验证码计时
    timing(){
      let num = 60
      this.codedisable = true
      this.Codetext = '获取验证码'+ '  ' +num
      this.time = setInterval(()=>{
        if(num>1){
          num--
          this.Codetext = '获取验证码'+ '  ' +num
        }else{
          clearInterval(this.time)
          this.time = null
          this.Codetext = '获取验证码'
          this.codedisable = false
        }
      },1000)
    },
    //获取验证码
    async sendCode(){
      try{
        let data = {
          ImgCode:this.imgcode
        }
        let result = await recommrndawrdsmswithdrawalsendsmscode(data)
        if(result.IsSuccess){
          this.$message.success(result.Message)
          this.imgcodeshow = false
          this.timing()
        }
      }catch(err){
        this.getimgCode()
      }
      // this.getimgCode()
    },
    //图片验证码
    async getimgCode(){
      try{
        let result = await recommrndawrdsmswithdrawalsmsimgcode()

        var image = new Image()
				image.src = result.img
        image.style.width = 200+'px'
        document.getElementById('imgcode').innerHTML = ''
        document.getElementById('imgcode').appendChild(image)
        // this.imgcodesrc = result
        // console.log(result)
      }finally{}
    },
    savebank(){
      if(!this.bankruForm.BankCode){
        this.$message.error('请选择开户银行')
      }else if(!this.bankruForm.CardNo){
        this.$message.error('请输入银行卡号')
      }else if(!this.bankruForm.CardHolderName){
        this.$message.error('请输入开户人姓名')
      }else{
        this.tosavebank()
      }
    },
    async tosavebank(){
      this.bankshowloading = true
      try{
        let result = await recommrndawrdwithdrawalaccountsave(this.bankruForm)
        if(result.IsSuccess){
          this.$message.success('保存成功')
          this.bankshow = false
          this.getmessage()
        }
      }finally{
        this.bankshowloading = false
      }
    },
    bankChange(val){
				this.bankList.map(item=>{
					if (item.value == val){
						this.bankruForm.BankName = item.label;
					}
				})
		},
    //获取银行信息
    getBankList(){
				var that = this
        that.bankloading = true
				axios.get('http://cdn.dkycn.cn/JSON/melyshop/adapay_bank.json?v='+new Date().getTime(), {})
					.then(function(response) {
						var dt = response.data;
						that.bankList = dt;
            // console.log(that.bankList)
					})
					.catch(function(error) {})
          .finally(()=>{
            that.bankloading = false
          })
		},
    changebank(){
      this.bankruForm = JSON.parse(JSON.stringify(this.msgruForm))
      this.bankshow = true
      this.getBankList()
    },
    closebank(){
      this.bankruForm = {}
      this.bankshow = false
    },
    applyfor(){
      if(!this.msgruForm.CardNo){
        this.$message.error('请添加到账银行卡信息')
      }else if(!this.Code){
        this.$message.error('请输入短信验证码')
      }else{
        this.$confirm(`请确认本次提现金额为${this.msgruForm.WaitDrawBalance}元，提现至${this.msgruForm.BankName}（${this.cutout(this.msgruForm.CardNo)}）`,'提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.toapplyfor()
        }).catch(()=>{})
      }
    },
    //确认提现
    async toapplyfor(){
      this.loading = true
      try{
        let data = {
          WithdrawalMoney:this.msgruForm.WaitDrawBalance,
          Code:this.Code
        }
        let result = await recommrndawrdmallapplywithdrawal(data)
        if(result.IsSuccess){
          this.$message.success(result.Message)
        }
      }finally{
        this.loading = false
        this.Code = ''
        this.getmessage()
      }
    },
    cutout(str){
      if(str){
        let start = str.substring(0,3)
        let end = str.substring(str.length-4,str.length)
        return start + '****' +end
      }
    },
    handleClick(e){
      // console.log(e.name)
      this.activeName = e.name
      if(e.name==1){
        this.getmessage()
      }else if(e.name==2){
        this.gettablelist()
      }
    },
    //申请提现数据
    async getmessage(){
      this.loading = true
      try{
        let result = await recommrndawrdmallwithdrawalaccount()
        if(result.IsSuccess){
          // console.log(result)
          this.msgruForm = result.Result
        }
      }finally{
        this.loading = false
      }
    },
    //提现记录
    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage
        } 
        let result = await recommrndawrdmallwithdrawalrecord(data)
        if(result.IsSuccess){
          this.tablelist = result.Result.Results
          this.total = result.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .flexRow2{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .label{
    width: 120px;
    text-align:right;
    font-size: 14px;
    font-weight: bold;
  }
  .bluebox{
    width:200px;
    height: 100px;
    border:1px solid #409eff;
    padding:0px 10px
  }
  .bluebox div{
    line-height: 1.5;
  }
  .flexCol{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .flexCol2{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ellipsis{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .smallfont{
    font-size: 12px;
    color: #999999;
  }
  .redstar ::v-deep .el-form-item__label::before{
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
</style>
<style>
  .table .el-table__body td,.el-table__body th{
    padding:0px !important;
    height:60px;
  }
</style>